<template>
  <div class="home">
    <span>Type in the name of the food you're looking up. You could click on the food item from the list to see its complete nutrition info.</span>
    <vue-bootstrap-typeahead
      :data="foods"
      v-model="foodSearch"
      size="lg"
      :serializer="getFoodLabel"
      placeholder="Type a food..."
      @hit="selectedFood = $event"
    >
      <!-- Begin custom suggestion slot -->
      <template slot="suggestion" slot-scope="{ data, htmlText }">
        <div class="d-flex align-items-center">
              <img
                class="rounded"
                :src="data.photo.thumb"
                style="width: 40px; height: 40px;" />
            <span style="padding-left: 15px;">
              <!-- Note: the v-html binding is used, as htmlText contains
     the suggestion text highlighted with <strong> tags -->
              <span class="ml-4" v-html="htmlText"></span><br/>
              <sub>Serving Size: {{ data.serving_qty + ' ' + data.serving_unit }}</sub><br/>
              <sub :style="getCopperStyle(data)">Copper Content: {{ getCopper(data) }}</sub>
            </span>
        </div>
      </template>
    </vue-bootstrap-typeahead>
    <b-card v-if="selectedFood"
      :title="getFoodLabel(selectedFood)"
      class="mb-2"
    >
      <b-card-text>
        <div v-if="selectedFood.photo.thumb">
          <img :src="selectedFood.photo.thumb" width="150px" height="150px">
        </div>
        <h6>Serving Size: {{ selectedFood.serving_qty + ' ' + selectedFood.serving_unit }}</h6>
        <h5 :style="getCopperStyle(selectedFood)">Copper Content: {{ getCopper(selectedFood) }}</h5>
        <sub>For information only. Always consult your doctor or dietician.</sub>
        <b-table striped hover :items="getFoodNutrients(selectedFood)"></b-table>

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import _ from 'underscore'

const API_SEARCH = 'https://trackapi.nutritionix.com/v2/search/instant?common=true&detailed=true&branded=false&query=:query'
const API_NUTRIENTS = 'https://trackapi.nutritionix.com/v2/utils/nutrients'

export default {
  name: 'CopperHome',
  components: {
  },
  data () {
    return {
      foods: [],
      foodSearch: '',
      selectedFood: null,
      copperContent: null,
      nutrients: {}
    }
  },

  methods: {
    async getFoods (query) {
      const res = await fetch(API_SEARCH.replace(':query', query), {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'x-app-id': '86ca11d2',
          'x-app-key': 'a633e972ce1d384e82f45f8da9761d27'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
      const suggestions = await res.json()
      let arrFoods = []
      if (suggestions.branded) {
        arrFoods = arrFoods.concat(suggestions.branded)
      }
      if (suggestions.common) {
        arrFoods = arrFoods.concat(suggestions.common)
      }
      this.foods = arrFoods
    },
    async getNutrients () {
      const res = await fetch(API_NUTRIENTS, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'x-app-id': '86ca11d2',
          'x-app-key': 'a633e972ce1d384e82f45f8da9761d27'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
      const arrNutrients = await res.json()
      arrNutrients.forEach(row => {
        this.nutrients[row.attr_id] = row
      })
    },
    selectFood (food) {
      this.selectedFood = food
      window.scrollTo(0, 0)
    },
    getFoodLabel (food) {
      let label = food.food_name

      if (food.brand_name_item_name) {
        label = food.brand_name_item_name
      }

      return label
    },
    getFoodNutrients (food) {
      const arrNutrients = []
      food.full_nutrients.forEach(row => {
        const nutrientDetail = this.nutrients[row.attr_id]

        if (nutrientDetail && nutrientDetail.name) {
          arrNutrients.push({
            Name: nutrientDetail.usda_nutr_desc,
            Amount: row.value + ' ' + nutrientDetail.unit,
            'Daily Value': nutrientDetail.fda_daily_value ? `${nutrientDetail.fda_daily_value}%` : ''
          })
        }
      })
      return arrNutrients
    },
    getCopper (food) {
      const arrCopper = food.full_nutrients.filter(row => row.attr_id === 312)
      const copperDetail = this.nutrients[312]
      if (arrCopper[0] && copperDetail) {
        return arrCopper[0].value + ` ${copperDetail.unit}`
      } else {
        return 'unknown'
      }
    },
    getCopperStyle (food) {
      const arrCopper = food.full_nutrients.filter(row => row.attr_id === 312)
      const copperDetail = this.nutrients[312]
      const style = {}
      if (arrCopper[0] && copperDetail) {
        if (parseFloat(arrCopper[0].value) >= 0.2) {
          style['background-color'] = '#ff9999'
        } else if (parseFloat(arrCopper[0].value) >= 0.1) {
          style.backgroundColor = 'YELLOW'
        } else {
          style.backgroundColor = '#66ff66'
        }
      }

      return style
    }
  },

  watch: {
    foodSearch: _.debounce(function (food) {
      this.getFoods(food)
      // this.selectedFood = null
    }, 500),
    selectedFood () {
      this.copperContent = null
    }
  },
  mounted () {
    this.getNutrients()
  }
}
</script>
